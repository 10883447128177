@import "./resetcss.scss";

* {
  outline: none;
  font-family: "Rubik", sans-serif;
}
//#00a3ff azul
//#fff branco

:root {
  --main-color: #fff;
  --bg-color: #00a3ff;
  --computer: rgb(0, 0, 0);
}

.shadow-overmap {
  position: relative;
  box-shadow: 0 3px 3px rgba(48, 48, 48, 0.425);
  z-index: 9;
}

.btn {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  font-size: 15px;
  padding: 15px 60px;
  border: 0px;
  border-radius: 3px;
  background: var(--main-color);
  color: black;
  outline: none;
  cursor: pointer;
  transition: 0.1s all;
  &:hover {
    background: #c7eaff;
  }
  &__arrow-down {
    width: 17px;
    fill: var(--bg-color);
    height: 17px;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
    animation-name: arrow-down;
    animation-duration: 1s;

    animation-timing-function: ease;
    animation-iteration-count: infinite;
    &:nth-of-type(2) {
      top: 46%;
      transform: translate(0, -46%);
    }
  }
}

.copyright {
  background: var(--main-color);
  color: var(--bg-color);
  width: 100%;
  position: fixed;
  padding: 5px 0;
  z-index: 99;
  span {
    font-family: "Rubik", sans-serif;
    padding: 0px 10px;
  }
  bottom: 0;
  text-align: right;
  left: 0;
}

.container {
  width: 95%;
  max-width: 1200px;
}

@media only screen and (max-width: 595px) {
  .copyright {
    text-align: center;
  }
}

.gm-style .gm-style-iw {
  top: 0 !important;
  left: 0 !important;

  display: block !important;
  padding: 0;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style div div div div div div div div {
  padding: 0;
  margin: 0;
  padding: 0;
  top: 0;
  font-size: 16px;
}

button.gm-ui-hover-effect {
  &:hover {
    opacity: 1 !important;
  }
  opacity: 0.5 !important;
  background: white !important;
  transition: all 0.1s;
  border-bottom-left-radius: 8px !important;
  padding: 0px !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 0px !important;
  right: 0px !important;
  img {
    padding: 0px !important;
    margin: 0px !important;
  }
}

map-info-window {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  text-align: center;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.2s;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 5px 0 25px 0;
}

::-webkit-scrollbar-thumb {
  transition: all 0.2s;
  background: linear-gradient(transparent, #81d0fd);
  border-radius: 7px;
  &:hover {
    background: linear-gradient(transparent, var(--bg-color));
  }
}
